//积分商品
import request from '@/plugins/axios';

/**
 * 分页查询积分商品
 */
export function integralGoodsPageGoods(params = {}) {
  return request({
    url: 'shop-mobile/integralGoods/pageGoods',
    method: 'get',
    params
  });
}

/**
 * 查询积分商品详情
 */
export function getIntegralGoodsInfo(params = {}) {
  return request({
    url: `shop-mobile/integralGoods/${params.integralGoodsId}`,
    method: 'get',
    params
  });
}

/**
 * 下单页面查询积分商品详情
 */
export function getPayIntegralGoodsInfo(data = {}) {
  return request({
    url: `shop-mobile/integralOrder/getIntegralGoods/${data.integralGoodsId}`,
    method: 'post',
    data
  });
}

/**
 * 积分商品确认订单
 */
export function integralSaveOrder(data) {
  return request({
    url: `shop-mobile/integralOrder/saveOrder`,
    method: 'post',
    data
  });
}

/**
 * 用户积分商品支付
 */
export function integralPayment(data) {
  return request({
    url: `shop-mobile/integralOrder/payOrder/${data.id}`,
    method: 'post',
    data
  });
}

/**
 * 用户积分商品支付
 */
export function integralPaymentAgain(data) {
  return request({
    url: `shop-mobile/integralOrder/payAgain/${data.id}`,
    method: 'post',
    data
  });
}

/**
 * 分页查询订单
 * /integralOrder/page
 */
export function integralOrderPage(params = {}) {
  return request({
    url: `shop-mobile/integralOrder/page`,
    method: 'get',
    params
  });
}

/**
 * 积分订单详情
 */
export function getIntegralOrderInfoById(params = {}) {
  return request({
    url: `shop-mobile/integralOrder/${params.integralOrderId}`,
    method: 'get',
    params
  });
}

/**
 * 查询物流（根据积分订单号）
 */
export function queryExpressByIntegralOrderId(params, opts) {
  return request({
    url: `shop-mobile/integralOrder/queryExpress/${params.id}`,
    method: 'get',
    params,
    ...opts
  });
}

/**
 * 积分商品确认收货
 */
export function integralRecordReceiving(data) {
  return request({
    url: `shop-mobile/integralOrder/confirmOrder/${data.id}`,
    method: 'post',
    data
  });
}

/**
 * 维权申请
 */
export function safeguardRights(data = {}) {
  return request({
    url: `shop-mobile/integralOrder/adviceOrder`,
    method: 'post',
    data
  });
}

/**
 * 获取退货地址
 */
export function getBackAddress(params) {
  return request({
    url: `shop-mobile/integralOrder/sendBackAddress`,
    method: 'get',
    params,
  });
}

/**
 * 发送退货单号
 */
export function sendOrderBack(params) {
  return request({
    url: `shop-mobile/integralOrder/sendBack/${params.integralOrderReturnId}`,
    method: 'get',
    params,
  });
}

/**
 * 维权分页查询订单
 */
export function orderReturnPage(params = {}) {
  return request({
    url: `shop-mobile/integralOrder/orderReturnPage`,
    method: 'get',
    params,
  });
}

/**
 * 用户积分收支记录
 */
export function getUserIntegralFlowPage(params = {}) {

  // return fetchList(params);

  return request({
    url: `shop-mobile/userIntegralFlow/page`,
    method: 'get',
    params,
  });
}

/**
 * 用户当前总积分
 */
export function getUserIntegral(params = {}) {

  // return getTotalPoint();

  return request({
    url: `shop-mobile/userIntegralFlow/getUserIntegral`,
    method: 'get',
    params,
  });
}

export function fetchList(params = {}) {
  // return request({
  //   url: '/shop/message/page',
  //   method: 'get',
  //   params,
  // })
  return new Promise(resolve => {
    const total = 31;
    const size = 10;
    let pages = parseInt(total / size + '') + (total % size ? 1 : 0);
    let { current: page = 1 } = params;
    let l = size * page <= total ? 10 : size * page - total < size ? total % size : 0;
    let list = [];
    let flg = () => Math.random() > .5;

    const remarkArr = ['购买商品', '积分商品维权', '积分商品购买', '商品维权'];

    while (l--) {
      let id = new Date().getTime() + '-' + l;
      const remarkArrIndex = parseInt(Math.random() * 4 + 1 + '', 10) - 1;
      const remark = remarkArr[remarkArrIndex];
      let z = parseInt(Math.random() * 4 + 1 + '', 10);
      let k = [1];
      while (z--)
        k.push(0);
      k = +k.join('');
      const integral = parseInt(Math.random() * k);
      const type = Math.random() > .5 ? 1 : 2;// 1 获取 2消耗
      const createTime = '2022-07-27 16:54:42';

      list.unshift({
        id,
        remark,
        type,
        integral,
        createTime,
      });
    }

    let data = {
      countId: null,
      current: page,
      hitCount: false,
      maxLimit: null,
      optimizeCountSql: true,
      orders: [],
      pages: pages,
      records: list,
      searchCount: true,
      size: size,
      total: total,
    };
    // TODO: XHR
    // console.log(params, JSON.parse(JSON.stringify(data)));

    setTimeout(() => {
      resolve(data);
    }, 321);
  });
}

export function getTotalPoint() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ point: 998 });
    }, 321);

  })
}
