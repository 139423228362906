<!-- 退换货申请 -->
<template>
  <page-container class="apply-for-return-container">
    <div class="order-info-container">
      <div class="order-goods-info">
        <goods-info
            view-model="small"
            :value="{
              goodsImgSrc: getFirstImg(orderInfo),
              goodsName: orderInfo.name,
              goodsDesc: orderInfo.subName,
            }"
        >
          <template #price>
            <div v-html="priceAndIntegralText"></div>
          </template>

          <template #price-right>
            ×{{ orderInfo.num }}
          </template>
        </goods-info>
      </div>

      <div class="order-detail-info">
        <div class="order-detail-info-item">
          <label>订单编号</label>
          <p>{{ orderInfo.orderNo }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>下单时间</label>
          <p>{{ orderInfo.orderTime }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>支付方式</label>
          <p>{{ payWayText }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>支付时间</label>
          <p>{{ orderInfo.payTime }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>商品总额</label>
          <p>{{ totalPriceText }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>运费</label>
          <p>+￥{{ orderInfo.carriage }}</p>
        </div>
        <div class="order-detail-info-item">
          <label style="line-height: 2.7">实付金额</label>
          <p v-html="lastPrice"></p>
        </div>
      </div>
    </div>

    <div class="form-item">
      <p>退货方式</p>
      <van-radio-group v-model="form.type">
        <van-cell-group>
          <van-cell
              v-for="item of typeList"
              :title="item.name"
              clickable
              @click="form.type = item.value"
          >
            <template #right-icon>
              <van-radio :name="item.value"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>

    <div class="form-item">
      <p>退货原因</p>
      <van-field
          v-model.trim="form.reason"
          type="textarea"
          rows="1"
          :autosize="true"
          :show-word-limit="true"
          maxlength="100"
          label-width="0"
          placeholder="请输入退货原因"
      />
    </div>

    <div class="submit-container">
      <van-button
          round
          type="primary"
          style="width: 100%"
          @click="safeguardRights"
      >
        申请售后
      </van-button>
    </div>

  </page-container>
</template>

<script>
import { getIntegralOrderInfoById, safeguardRights } from '@/api/integralGoods';
import { Dialog, Toast } from 'vant';
import GoodsInfo from '@/components/GoodsInfo';
import { CANG_KU_ZHONG, DAI_FA_HUO } from '@/const/orderStatus';
import { objectGetter } from '@/util';

export default {
  name: 'applyForReturn',
  components: { GoodsInfo },
  data() {
    return {
      orderInfo: { status: '' },
      form: {
        orderId: '',
        reason: '',
        type: '',
      },
    };
  },
  computed: {
    // 商品单价
    priceAndIntegralText() {
      let { shopAmount: goodsPrice, shopIntegralUsed: integralUsed } = this.orderInfo || {};
      let priceHtml = goodsPrice ? [`<span class="integral-price">${goodsPrice}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    // 支付方式
    payWayText() {
      let { orderInfo: { payWay = '' } } = this;
      return {
        '01': '微信支付',
        '02': '支付宝支付',
        '03': '银联支付',
        '04': '余额支付',
        '05': '积分支付',
        '06': '微信加积分支付',
        '07': '支付宝加积分',
        '08': '银联加积分',
        '09': '余额加积分',
      }[payWay] || '';
    },
    // 商品总额
    totalPriceText() {
      let { amount, integralUsed } = this.orderInfo || {};
      let priceHtml = amount ? ['￥' + amount] : [];
      let integralUsedHtml = integralUsed ? [integralUsed + '积分'] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    lastPrice() {
      let { amount, integralUsed } = this.orderInfo || {};
      let priceHtml = amount ? [`<span class="integral-price">${amount}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    //status
    typeList() {
      let { status } = this.orderInfo;
      if (!status)
        return [];
      // 02 DAI_FA_HUO 只退款
      // 09 CANG_KU_ZHONG 只退款  type 03
      let allowReturnGoodsAndMoney = [DAI_FA_HUO, CANG_KU_ZHONG].indexOf(status) < 0;
      let returnGoodsAndMoneyItem = allowReturnGoodsAndMoney ? [{ value: '01', name: '退货/积分 退款' }] : [];
      let returnMoneyItem = [
        {
          value: status === CANG_KU_ZHONG ? '03' : '02',
          name: '退款/积分',
        }
      ];
      return [...returnGoodsAndMoneyItem, ...returnMoneyItem];
    },
  },
  created() {
    let { integralOrderId } = this.$route.query;

    // integralOrderId = 610;

    this.form.orderId = integralOrderId;
    getIntegralOrderInfoById({ integralOrderId }).then(res => {
      this.orderInfo = res || {};
    });
  },
  methods: {
    getFirstImg(order) {
      return objectGetter(order, 'imageList.0.url');
    },
    //orderId、type( 01.退货退款 02.退款)、reason
    safeguardRights() {
      if (!this.form.type)
        return Toast('请选择退款方式');

      if (!this.form.reason)
        return Toast('请输入退款原因');

      Dialog.confirm({
        title: `是否确申请售后么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            safeguardRights({
              ...this.form,
            }).then(res => {
              done();
              if (res === true) {
                this.$store.commit('integralOrderList/setRefreshing', true);
                this.$router.back();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="less" scoped>
.apply-for-return-container {
  padding-bottom: 74px;

  .order-info-container {
    background-color: #fff;

    .order-goods-info {
      padding: 10px;
    }

    .order-detail-info {
      padding: 10px;

      .order-detail-info-item {
        display: flex;
        line-height: 2;
        font-size: 14px;

        & > uni-label {
          flex-shrink: 0;
          width: 70px;
          text-align: right;
          color: #333;

          &:after {
            content: ':';
          }
        }

        & > p {
          flex: 1;
          text-align: right;
          box-sizing: border-box;
          padding: 0 10px;
          color: #666;
        }
      }
    }
  }

  .form-item {
    margin: 10px;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 10px;

    & > p {
      padding: 6px 0 8px 16px;
      font-size: 16px;
      color: #666;
    }
  }

  .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border: none;
  }

  .return-reason {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }

  .submit-container {
    position: fixed;
    z-index: 1;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    width: 100%;
    background-color: #fff;
  }

  /deep/ .goods-image {
    height: 0;
  }

  /deep/ .goods-info-item .goods-info-detail {
    height: auto;

    .goods-name {
      margin-bottom: .5em;
      -webkit-line-clamp: 2;
    }

    .goods-price {
      line-height: 20px;
    }
  }

  /deep/ .integral-price {
    font-size: 18px;
    font-weight: bolder;
    color: #e93b3d;

    .num {
      line-height: 20px;
    }

    &:before {
      content: '¥ ';
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .integral {
    font-size: 18px;
    font-weight: bolder;
    color: #ffa800;

    &:after {
      content: '积分';
      margin-left: 3px;
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .num {
    text-align: right;
  }

  .last-price-container {
    padding: 25px 15px;
    text-align: right;

    /deep/ .integral-price {
      font-size: 22px;

      &:before {
        font-size: 14px;
      }
    }

    /deep/ .integral {
      font-size: 22px;

      &:after {
        font-size: 14px;
      }
    }

    .last-price {
      //font-size: 24px;
      //font-weight: bolder;
      //color: #e93b3d;
      //white-space: nowrap;
      //overflow-x: auto;
      //
      //&:before {
      //  content: '¥ ';
      //  font-size: 14px;
      //  font-weight: normal;
      //}
    }
  }

}
</style>
